import React from 'react';
export class BasicTooltip extends React.Component {

    render() {

        return (
            <>
                <p data-id="tooltip" class="basic-tooltip" title="This is a tooltip">tooltip</p>
            </>
        );
    }
};

